/* eslint-disable react/jsx-indent */
/* eslint-disable nordic/i18n-jsx-no-literals */
/**
 * Module dependencies
 */
const React = require('react');
const Head = require('nordic/head');
const Script = require('nordic/script');
const Page = require('nordic/page');
const Style = require('nordic/style');
const Card = require('@andes/card');
const { CardContent } = require('@andes/card');
const Button = require('@andes/button');

const HowItWorksView = (props) => {
  const { device } = props;
  return (
    <Page name="how_it_works" state={props} styles={false}>
      <Head>
        <meta name="title" content="Mercado Libre Global Selling | Sell in Latin America" />
        <meta name="description" content="Expand your business in the Latin market with Mercado Libre. Discover Global Selling, a platform in which you can easily manage your business." />
        <title>Mercado Libre Global Selling | Sell in Latin America</title>
      </Head>
      <Style
        href="how_it_works.css"
        inline
      />
      <div>
        <div className="portada-section">
          <h1 className="portada-section__title">How Global Selling Platform works</h1>
        </div>
        <div className="sell-section">
          <span className="display-image sell-section__image-desktop" />
          <div className="sell-section__texts-conteiner">
            <h2 className="sell-section__title">The easiest way to sell in Latin America</h2>
            <p className="sell-section__subtitle">With Global Selling <b>you can manage your business in 4 different Latin American marketplaces, all from a single account.</b> Once you’ve signed up, <b>you can list your products for free, receive payments in USD,</b> and communicate with your Latin American buyers using our automated translation tool.</p>
          </div>
        </div>
        <div className="free-listing-section">
          <h2 className="free-listing-section__title">List for free with Global Selling</h2>
          <div className="free-listing-section__info-conteiner">
            <div className="free-listing-section__info-item excel-info-item">
              <span className="display-image free-listing-section__excel-file" />
              <h3 className="free-listing-section__info-title">Via Excel spreadsheet</h3>
              <p className="free-listing-section__info-subtitle excel-subtitle">Upload your catalog within just a few steps.</p>
              <a href="https://sellers.mercadolibre.com/news/how-to-list-my-products/" target="_blank" rel="noopener noreferer">Learn more</a>
            </div>
            <div className="free-listing-section__info-item api-info-item">
              <span className="display-image free-listing-section__api-computer" />
              <h3 className="free-listing-section__info-title">Via Global Selling API</h3>
              <p className="free-listing-section__info-subtitle api_subtitle">Integrate Mercado Libre Global Selling with your own platform.</p>
              <a href="https://global-selling.mercadolibre.com/devsite/list-products-global-selling" target="_blank" rel="noopener noreferer">Learn more</a>
            </div>
          </div>
        </div>
        <div className="restricted-products-section">
          <Card
            shadow="elevated"
            className="restriced-products-section__card"
          >
            <CardContent>
              <div className="restriced-products-section__card-content">
                <div className="restricted-products-section__title-conteiner">
                  <div className="rectangle-title" />
                  <h3 className="restricted-products-section__title">Restricted products</h3>
                </div>
                <p className="restricted-products-section__text">Before listing, review which are the <a href="https://global-selling.mercadolibre.com/help/16621" target="_black" rel="noopener noreferrer">restricted products of the destination country.</a> If you plan on using one of our partnered carriers, bear in mind <a href="http://cbt.mercadolibre.com/si/docs/ProductDimensionRestrictions.pdf?utm_source=vendedores&utm_medium=referral&utm_campaign=Core_ListMyProducts&utm_content=productdimensionsandweightrestrictions" target="_blank" rel="noopener noreferrer">the package size and weight restrictions.</a></p>
              </div>

            </CardContent>
          </Card>
        </div>
        <div className="international-payments-section">
          <h2 className="international-payments-section__title">International payment flows</h2>
          <div className="international-payments-section__content">
            <span className="display-image international-payments-section__money-image" />
            <div className="international_payments-section__texts">
              <div className="international_payments-section__content-title">
                <div className="rectangle-title" />
                <p className="international-payments-section__subtitle">Forget about currency fluctuations</p>
              </div>
              <div className="international-payments-section__text">
                <p>Despite the currency of your customers, <b>you’ll receive an international wire in USD</b> (minus applicable <a href="https://global-selling.mercadolibre.com/help/17600" target="_blank" rel="noopener noreferrer">fees</a>). This payment will reflect every 2 weeks either on your bank or Payoneer account.</p>
              </div>
            </div>
          </div>
          <Card
            shadow="elevated"
            className="international-payments-section__card"
          >
            <CardContent>
              <div className="international-payments-section__card-content">
                <div className="international-payments-section__card-title">
                  <div className="international-payments-section__rectangle-title" />
                  <p className="international-payments-section__title-text">Keep in mind!</p>
                </div>
                <div className="international-payments-section__card-subtitle">
                  <span className="international-payments-section__dot" />
                  <p className="international-payments-section__subtitle-text">The minimum amount to be transferred is 500 USD. If your pending transfer amount is lower, payment will be deferred until the minimum amount is reached.</p>
                </div>
                <div className="international-payments-section__card-subtitle">
                  <span className="international-payments-section__dot" />
                  <p className="international-payments-section__subtitle-text">Our <a href="https://global-selling.mercadolibre.com/help/17600" target="_blank" rel="noopener noreferer">selling fee</a> includes free installments for the buyer, a common payment method used in Latin American.</p>
                </div>
                <div className="international-payments-section__button">
                  <Button hierarchy="loud" size="large" href="https://global-selling.mercadolibre.com/help/22845" target="_blank">Learn more about payments</Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="shipping-solution-section">
          <h2 className="shipping-solution-section__title">Shipping solutions to Latin America</h2>
          <div className="shipping-solution-section__cards-conteiner">
            <Card
              shadow="elevated"
              className="shipping-solution-section__card"
            >
              <CardContent>
                <span className="display-image shipping-solution-section__envios-image" />
                <div className="shipping-solution-section__card-text-conteiner">
                  <h3 className="me-title">Mercado Envios Full</h3>
                  <Button hierarchy="quiet" size="large" href="/landing/shipping-solutions#full">Discover more</Button>
                </div>
              </CardContent>
            </Card>
            <Card
              shadow="elevated"
              className="shipping-solution-section__card"
            >
              <CardContent>
                <span className="display-image shipping-solution-section__plane-image" />
                <div className="shipping-solution-section__card-text-conteiner">
                  <h3>Mercado Envios Direct to consumers</h3>
                  <Button hierarchy="quiet" size="large" href="/landing/shipping-solutions#direct">Discover more</Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="reputation-section">
          <h2>Improve your sales with our Seller Reputation System and Mercado Libre Ads</h2>
          <Card
            shadow="elevated"
            className="reputation-section__card"
          >
            <CardContent>
              <div className="reputation-section__card-content">
                <span className="display-image reputation-section__reputation-icon" />
                <div className="reputation-section__card-texts">
                  <div className="reputation-section__title-conteiner">
                    <div className="rectangle-title" />
                    <p className="reputation-section__card-title">Reputation system:</p>
                  </div>
                  <div className="reputation-section__card-text">
                    Having a <b>good seller reputation</b> has the following benefits:
                      <ul>
                        <li>Your products rank higher, thus, more exposure.</li>
                        <li>Your engagement and conversion rates boost since reputation influences the purchasing decision of your buyers.</li>
                        <li>You save up to 60% in shipping costs.</li>
                      </ul>
                  </div>
                  <div className="reputation-section__card-link">
                    <a href="https://sellers.mercadolibre.com/news/seller-reputation-learn-how-it-works/" target="_blank" rel="noopener noreferer">Learn more about seller Reputation</a>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card
            shadow="elevated"
            className="reputation-section__card"
          >
            <CardContent>
              <div className="reputation-section__card-content">
                <span className="display-image reputation-section__ads-icon" />
                <div className="reputation-section__card-texts">
                  <div className="reputation-section__title-conteiner">
                    <div className="rectangle-title" />
                    <p className="reputation-section__card-title">Mercado Ads:</p>
                  </div>
                  <p className="reputation-section__card-text">This built-in solution lets you manage your advertising campaigns. With it, you’ll be able to customize your budget at any time and enhance your <b>products’ visibility in the marketplace.</b> It’s a pay-as-you-go feature, meaning <b>you only pay once</b> a user clicks on your ads.</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="support-section">
          <h2>Keep continuous communication with your buyers with a realtime translation system</h2>
          <div className="support-section__communication-conteiner support-conteiner">
            <div className="support-section__communication-info">
              <span className="display-image support-section__translation-image" />
              <h3>Translations between English, Spanish and Portuguese</h3>
              <p>Our integrated translator allows you <b>to handle all processes in English,</b> covering translations for pre and post-sale questions as well as claims management. Read the following pieces of advice to <a href="https://sellers.mercadolibre.com/news/turn-questions-into-orders/" target="_blank" rel="noopener noreferer">turn questions into orders</a> and <a href="https://sellers.mercadolibre.com/news/learn-how-to-answer-questions-and-boost-your-sales/" target="_blank" rel="noopener noreferer">boost your sales.</a></p>
            </div>
            <div className="support-section__communication-info post-sale">
              <span className="display-image support-section__post-sale-image" />
              <h3 className="support-section__post-sale-title">Post-sale response service level</h3>
              <p>Depending on your customer service and your questions response time, <b>you can create and maintain a good Seller’s Reputation.</b> Aim to reply every buyer contact within 24hrs to <a href="https://sellers.mercadolibre.com/news/after-sales-service/" target="_blank" rel="noopener noreferer">avoid unnecessary complaints.</a></p>
            </div>
          </div>
          <h2 className="related-articles">Related articles</h2>
          <div className="support-section__communication-conteiner related-conteiner">
            <div className="support-section__communication-info related-articles">
              <span className="display-image related-articles__seller-learning" />
              <h3 className="support-section__related-articles-title">Seller Learning Center</h3>
              <p className="support-section__related-articles-text seller-learning">Learn how to expand your business in Latin America.</p>
              <a href="https://sellers.mercadolibre.com/" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
            <div className="support-section__communication-info related-articles">
              <span className="display-image related-articles__help-center" />
              <h3 className="support-section__related-articles-title">Help Center</h3>
              <p className="support-section__related-articles-text help-center">Read the frequently asked questions.</p>
              <a href="https://global-selling.mercadolibre.com/help" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
            <div className="support-section__communication-info related-articles">
              <span className="display-image related-articles__developers" />
              <h3 className="support-section__related-articles-title">Developers solutions</h3>
              <p className="support-section__related-articles-text developers-solution">Learn about our API available features.</p>
              <a href="https://global-selling.mercadolibre.com/devsite?utm_source=vendedores&utm_medium=referral&utm_campaign=Core_Integratenewapis&utm_content=developerssite" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
          </div>
        </div>
      </div>
      <Script src="vendor.js" />
    </Page>
  );
};

/**
 * Inject i18n context as into InboundsView.
 */
module.exports = HowItWorksView;
