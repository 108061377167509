/* eslint-disable react/jsx-indent */
/**
 * Styles
 */
require('../pages/newLandingSellers/howItWorks/how-it-works.scss');

/**
 * Module dependencies
 */
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');
const { AndesProvider } = require('@andes/context');
const HowItWorksView = require('../pages/newLandingSellers/howItWorks/view');

/**
 * i18n
 */
const { locale } = window.__PRELOADED_STATE__;

/**
 * Mount HomeView on client
 */
hydrateRoot(
    document.getElementById('root-app'),
    <AndesProvider locale={locale}>
        <HowItWorksView {...window.__PRELOADED_STATE__} />
    </AndesProvider>,
);
